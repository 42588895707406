import AsyncAlpine from "async-alpine";
import { Livewire, Alpine } from "../../../../../vendor/livewire/livewire/dist/livewire.esm.js";
import enquireModalAlpineStore from "./enquireModalAlpineStore.js";

/**
 * AsyncAlpine & AlpineJS init (must come after other libraries).
 *
 * Alpine.start() not called at the end of this script because Alpine
 * is initialised via Livewire.start(), within checkIn.js.
 *
 * @see https://async-alpine.dev/docs/install/#npm
 * @see https://alpinejs.dev/essentials/installation#as-a-module
 * @see https://livewire.laravel.com/docs/installation#manually-bundling-livewire-and-alpine
 */
export default function setupAlpine() {
  window.Alpine = Alpine;

  AsyncAlpine.init(Alpine);

  enquireModalAlpineStore();

  AsyncAlpine.data("amanMap", () => import("../components/amanMap.ts"));

  AsyncAlpine.data("ammapAsiaMapComponent", () => import("../components/ammapAsiaMapComponent.js"));

  AsyncAlpine.data("bannerSlider", () => import("../components/bannerSlider.js"));

  AsyncAlpine.data("carouselComponent", () => import("../components/carouselComponent.js"));

  AsyncAlpine.data("itineraryMap", () => import("../components/itineraryMap.js"));

  AsyncAlpine.data("hotelsCarouselComponent", () => import("../components/hotelsCarouselComponent.js"));

  AsyncAlpine.data("enquireDestinationsSelect", () => import("../components/enquireDestinationsSelect.js"));

  AsyncAlpine.data("enquireAmanJetExpeditionSelect", () => import("../components/enquireAmanJetExpeditionSelect.js"));

  AsyncAlpine.data("pageTabs", () => import("../components/pageTabs.js"));

  AsyncAlpine.data("siteHeaderComponent", () => import("../components/siteHeaderComponent.js"));

  AsyncAlpine.data("siteHeaderSearchModalComponent", () => import("../components/siteHeaderSearchModalComponent.js"));

  AsyncAlpine.data("tabSlider", () => import("../components/tabSlider.js"));

  AsyncAlpine.data("vimeoEmbed", () => import("../components/vimeoEmbed.js"));

  AsyncAlpine.start();

  Livewire.start();
}
