import mouseflowStart from "./mouseflowStart.js";

/**
 * @see https://github.com/statamic/cms/blob/4.x/src/StaticCaching/Cachers/FileCacher.php Similar technique.
 */
export default function checkIn(eventId, visitorUuid = null) {
  /** @var {CheckInData} data */
  let data;

  window.axios
    .post(route("ajax.check-in"))
    .then(function (response) {
      return new Promise((resolve) => {
        data = response.data;

        const csrfToken = data.csrfToken;

        /** Axios CSRF token. */
        window.axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

        /** Livewire CSRF token. */
        window.livewireScriptConfig["csrf"] = csrfToken;

        /** Update form token hidden input value. */
        document.querySelectorAll('input[name="_token"]').forEach(function (csrfTokenInput) {
          csrfTokenInput.setAttribute("value", csrfToken);
        });

        document.querySelectorAll('meta[name="csrf-token"]').forEach(function (csrfTokenMeta) {
          csrfTokenMeta.setAttribute("content", csrfToken);
        });

        /**
         * Dispatch a Livewire event.
         * @see App\Livewire\NewsletterSignupFormSection::$listeners
         */
        window.Livewire.dispatch("check-in-completed");

        resolve();
      });
    })
    .then(function () {
      return window.axios.post(route("ajax.page-view"), {
        url: window.location.href,
        event_uuid: eventId,
        visitor_uuid: visitorUuid,
        document: {
          title: document.title,
          referrer: document.referrer,
        },
      });
    })
    .then(() => mouseflowStart(data.countryCode))
    .catch(function (error) {
      console.error(error);
    });
}
