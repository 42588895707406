/** https://laravel.com/docs/10.x/vite#blade-processing-static-assets */
import.meta.glob(["../images/**", "../video/**"]);

/** Packages. */
import Flickity from "flickity";
import "instant.page";
import SimpleParallax from "simple-parallax-js/vanilla";

/** Modules. */
import checkIn from "./app/modules/checkIn.js";
import cookieConsent from "./app/modules/cookieConsent.js";
import setupAlpine from "./app/modules/setupAlpine.js";
import setupAxios from "./app/modules/setupAxios.js";
import setupCash from "./app/modules/setupCash.js";
import setupEventId from "./app/modules/setupEventId.js";
import updateViewportHeightProperty from "./app/modules/updateViewportHeightProperty.js";
import { loadAmmap, screenUp } from "./app/modules/helpers.js";

/** Ammap loader helper. TODO: refactor usages to async Alpine components. */
window.loadAmmap = loadAmmap;

/**
 * Initialise packages & modules.
 */

setupAxios();

setupCash();

setupEventId();

/** Initialise Alpine.js & Livewire (must be after other packages). */
setupAlpine();

checkIn(window.rlEventId);

updateViewportHeightProperty();

cookieConsent();

/** Import legacy filter functions partial. */
import "./app/partials/filter.js";

/** Flickity carousel default options. */
const carouselOptions = {
  cellSelector: ".js-carousel__cell",
  draggable: ">1",
  dragThreshold: 10,
  groupCells: true,
  pageDots: false,
  lazyLoad: true,
  wrapAround: false,
  adaptiveHeight: true,
  percentPosition: true,
};

function initFlickityCarousel(carouselElement) {
  let options = Object.create(carouselOptions);

  /** Options: Initial Index. */
  let initialIndex = carouselElement.getAttribute("data-initial-index");
  options.initialIndex = initialIndex ? parseInt(initialIndex) : 0;

  /** Options: lazy load value. */
  let lazyLoad = carouselElement.getAttribute("data-flickity-lazyload");

  if (lazyLoad) {
    if (lazyLoad === "false") {
      options.lazyLoad = false;
    } else {
      options.lazyLoad = parseInt(lazyLoad);
    }
  }

  /** Options: Group Cells (Small screen size up). */
  if (carouselElement.classList.contains("js-carousel--grouped") && screenUp(768)) {
    options.groupCells = 3;
    options.wrapAround = true;
    options.adaptiveHeight = false;
  }

  /** Options: Peek Cells `[data-flickity-peek]`. */
  if ("flickityPeek" in carouselElement.dataset) {
    if (screenUp(468)) {
      options.wrapAround = true;
      options.adaptiveHeight = false;
    }

    /** Small (sm) breakpoint up. */
    if (screenUp(768)) {
      options.groupCells = 2;
    }

    /** Large (lg) breakpoint up. */
    if (screenUp(1200)) {
      options.groupCells = 3;
    }
  }

  /** Enable wrap around `[data-flickity-wrap]`. */
  if ("flickityWrap" in carouselElement.dataset) {
    options.wrapAround = true;
  }

  /** Disable wrap around `[data-flickity-no-wrap]`. */
  if ("flickityNoWrap" in carouselElement.dataset) {
    options.wrapAround = false;
  }

  /** Disable prev/next buttons `[data-flickity-no-buttons]`. */
  if ("flickityNoButtons" in carouselElement.dataset) {
    options.prevNextButtons = false;
  }

  /** Enable Page Dots. TODO: replace with data attr. */
  if (carouselElement.classList.contains("js-carousel--dots")) {
    options.pageDots = true;
  }

  /** Enable Group Cells `[data-flickity-group-cells]`. */
  if ("flickityGroupCells" in carouselElement.dataset) {
    options.groupCells = true;
  }

  /** Disable Group Cells `[data-flickity-no-group-cells]`. */
  if ("flickityNoGroupCells" in carouselElement.dataset) {
    options.groupCells = false;
  }

  /** Cell alignment `[data-flickity-cell-align="left|right|center"]`. */
  if ("flickityCellAlign" in carouselElement.dataset) {
    options.cellAlign = carouselElement.dataset.flickityCellAlign;
  }

  /** Disable dragging `[data-flickity-no-drag]`. */
  if ("flickityNoDrag" in carouselElement.dataset) {
    options.draggable = false;
  }

  /** Disable adaptive height `[data-flickity-no-adaptive-height]`. */
  if ("flickityNoAdaptiveHeight" in carouselElement.dataset) {
    options.adaptiveHeight = false;
  }

  /** Initialise Flickity. */
  let flickityCarousel = new Flickity(carouselElement, options);

  /** Carousel external nav. */
  let carouselNavElement = carouselElement.querySelector(".js-carousel-nav");

  if (carouselNavElement) {
    /** Nav elements. */
    let carouselNavButtonElements = carouselNavElement.querySelectorAll(".nav-link");

    /** Select cell on button click. */
    carouselNavButtonElements.forEach(function (carouselNavButtonElement) {
      carouselNavButtonElement.addEventListener("click", function (event) {
        event.preventDefault();

        let index = event.target.getAttribute("data-flickity-nav-index");

        flickityCarousel.select(index);
      });
    });

    /** Set active nav item. */
    flickityCarousel.on("select", function (index) {
      carouselNavButtonElements.forEach(function (carouselNavButtonElement) {
        carouselNavButtonElement.classList.remove("active");
      });

      carouselNavButtonElements[index].classList.add("active");
    });
  }
}

/** Document ready. */
document.addEventListener("DOMContentLoaded", function () {
  /**
   * Flickity: equal height cells.
   * .flickity-resize .flickity-equal-height
   * https://github.com/metafizzy/flickity/issues/534
   * https://codepen.io/desandro/pen/ZXEGVq
   */
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add("flickity-resize");
  };

  Flickity.createMethods.push("_createResizeClass");

  let flickityResize = Flickity.prototype.resize;

  Flickity.prototype.resize = function () {
    this.element.classList.remove("flickity-resize");
    flickityResize.call(this);
    this.element.classList.add("flickity-resize");
  };

  /** Flickity: Carousels. */
  let carouselElements = document.querySelectorAll(".js-carousel");

  if (carouselElements.length > 0) {
    carouselElements.forEach(function (carouselElement) {
      initFlickityCarousel(carouselElement);
    });
  }

  /**
   * Parallax images.
   * @see https://simpleparallax.com
   */
  if (screenUp(768)) {
    /** Parallax background images with content overlays. */
    const parallax_bg_images = document.querySelectorAll(".js-parallax-bg__image");

    if (parallax_bg_images.length) {
      new SimpleParallax(parallax_bg_images, {
        delay: 0,
        customWrapper: ".js-parallax-bg",
        scale: 1.15,
      });
    }
  }
});
