import Cookies from "js-cookie";

export default function cookieConsent() {
  const cookieName = "gdpr";

  document.addEventListener("DOMContentLoaded", function () {
    let cookieConsentElement = document.getElementById("js-cookie-consent");

    /** Cookie consent bar. */
    if (cookieConsentElement && Cookies.get(cookieName) !== "disable") {
      /** Remove `display: none` style. */
      cookieConsentElement.style.display = "";
      cookieConsentElement.setAttribute("aria-hidden", "false");

      /** Set cookie. */
      Cookies.set(cookieName, "disable", { expires: 365 * 20, sameSite: "Strict" });

      /** Handle 'OK' button. */
      document.getElementById("js-close-gdpr").addEventListener("click", () => {
        cookieConsentElement.remove();
      });
    }
  });
}
