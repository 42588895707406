/**
 * @deprecated
 */
window.filter_itin_theme = function () {
  const themeid = $("select[name='itin_theme'] option:checked").val();
  const themelists = $("#selected_themeid").val();

  if (themeid > 0) {
    if (themelists.includes(themeid) === false) {
      $("#selected_themeid_default").val(themeid);

      var vtheme = $("select[name='itin_theme'] option:checked").html();

      gen_country_selection();
      gen_month_selection();

      $(".js-filter-chips").append(
        `<div class="filter-chip" id="stheme${themeid}"><img src="/imgs/x.png" alt="Remove" onclick="close_theme('${themeid}');">${vtheme}</div>`,
      );

      window.add_themeid(themeid);

      show_itinerary_in_filter();
    }
  } else {
    $("#selected_themeid").val("");

    gen_country_selection();
    gen_month_selection();
    show_itinerary_in_filter();
  }
};

/**
 * @deprecated
 */
window.filter_itin_month = function () {
  const monthid = $("select[name='itin_month'] option:checked").val();
  const monthlists = $("#selected_monthid").val();

  if (monthid > 0) {
    if (monthlists.includes(monthid) === false) {
      $("#selected_monthid_default").val(monthid);

      var vmonth = $("select[name='itin_month'] option:checked").html();

      gen_country_selection();
      gen_theme_selection();

      $(".js-filter-chips").append(
        `<div class="filter-chip" id="smonth${monthid}"><img src="/imgs/x.png" alt="Remove" onclick="close_month('${monthid}');">${vmonth}</div>`,
      );

      window.add_monthid(monthid);

      show_itinerary_in_filter();
    }
  } else {
    $("#selected_monthid").val("");

    gen_country_selection();
    gen_theme_selection();
    show_itinerary_in_filter();
  }
};

/**
 * @deprecated
 */
window.filter_itin_country = function () {
  const countryid = $("select[name='itin_country'] option:checked").val();

  const countrylists = $("#selected_countryid").val();

  if (countryid > 0) {
    if (countrylists.includes(countryid) === false) {
      $("#selected_countryid_default").val(countryid);

      var vcountry = $("select[name='itin_country'] option:checked").html();

      if (vcountry === "Uae") {
        vcountry = "UAE";
      }

      gen_month_selection();
      gen_theme_selection();

      $(".js-filter-chips").append(
        `<div class="filter-chip" id="scountry${countryid}"><img src="/imgs/x.png" alt="Remove" onclick="close_country('${countryid}');">${vcountry}</div>`,
      );

      add_countryid(countryid);
      show_itinerary_in_filter();
    }
  } else {
    $("#selected_countryid").val("");

    gen_month_selection();
    gen_theme_selection();
    show_itinerary_in_filter();
  }
};

/**
 * @deprecated
 */
function gen_country_selection() {
  var themeid = $("#selected_themeid_default").val();
  var monthid = $("#selected_monthid_default").val();

  var url = route("ajax.itinerary-search");

  var infodata = `monthid=${monthid}&themeid=${themeid}&process=gen_country_selection`;

  $.post(url, infodata, function (data) {
    $("select[name='itin_country']").html(data);

    $("select[name='itin_country'] option:first-of-type").attr("selected", "selected");
  });
}

/**
 * @deprecated
 */
function gen_month_selection() {
  var themeid = $("#selected_themeid_default").val();
  var countryid = $("#selected_countryid_default").val();

  var infodata = `countryid=${countryid}&themeid=${themeid}&process=gen_month_selection`;

  $.post(route("ajax.itinerary-search"), infodata, function (data) {
    $("select[name='itin_month']").html(data);

    $("select[name='itin_month'] option:first-of-type").attr("selected", "selected");
  });
}

/**
 * @deprecated
 */
function gen_theme_selection() {
  var monthid = $("#selected_monthid_default").val();

  var countryid = $("#selected_countryid_default").val();

  var infodata = `monthid=${monthid}&countryid=${countryid}&process=gen_theme_selection`;

  $.post(route("ajax.itinerary-search"), infodata, function (data) {
    $("select[name='itin_theme']").html(data);

    $("select[name='itin_theme'] option:first-of-type").attr("selected", "selected");
  });
}

/**
 * @deprecated
 */
function clear_selection() {
  var themeids = $("#selected_themeid").val();

  if (themeids == "") {
    $("#selected_themeid_default").val("");
  }

  var monthids = $("#selected_monthid").val();

  if (monthids == "") {
    $("#selected_monthid_default").val("");
  }

  var countryids = $("#selected_countryid").val();

  if (countryids == "") {
    $("#selected_countryid_default").val("");
  }

  gen_theme_selection();
  gen_month_selection();
  gen_country_selection();
}

/**
 * @deprecated
 */
window.close_theme = function (themeid) {
  window.del_themeid(themeid);

  $(`#stheme${themeid}`).remove();

  show_itinerary_in_filter();
  clear_selection();
};

/**
 * @deprecated
 */
window.close_month = function (monthid) {
  window.del_monthid(monthid);

  $(`#smonth${monthid}`).remove();

  show_itinerary_in_filter();
  clear_selection();
};

/**
 * @deprecated
 */
window.close_country = function (countryid) {
  del_countryid(countryid);

  $(`#scountry${countryid}`).remove();

  show_itinerary_in_filter();

  clear_selection();
};

/**
 * @deprecated
 */
window.add_themeid = function (themeid) {
  var themeids = $("#selected_themeid").val();

  if (themeids != "") themeids += "|";
  if (themeid != "") themeids += themeid;

  $("#selected_themeid").val(themeids);
};

/**
 * @deprecated
 */
window.add_monthid = function (monthid) {
  var months = $("#selected_monthid").val();

  if (months != "") months += "|";
  if (monthid != "") months += monthid;

  $("#selected_monthid").val(months);
};

/**
 * @deprecated
 */
function add_countryid(countryid) {
  var countrys = $("#selected_countryid").val();

  if (countrys != "") countrys += "|";
  if (countryid != "") countrys += countryid;

  $("#selected_countryid").val(countrys);
}

/**
 * @deprecated
 */
window.del_themeid = function (themeid) {
  var themeids = $("#selected_themeid").val();

  themeids = themeids.replace(themeid, "");
  themeids = themeids.replace("|", "");

  $("#selected_themeid").val(themeids);
};

/**
 * @deprecated
 */
window.del_monthid = function (monthid) {
  var monthids = $("#selected_monthid").val();

  monthids = monthids.replace(monthid, "");
  monthids = monthids.replace("|", "");

  $("#selected_monthid").val(monthids);
};

/**
 * @deprecated
 */
function del_countryid(countryid) {
  var countryids = $("#selected_countryid").val();

  countryids = countryids.replace(countryid, "");
  countryids = countryids.replace("|", "");

  $("#selected_countryid").val(countryids);
}

/**
 * @deprecated
 */
function show_itinerary_in_filter() {
  $("#show_itin_area").html("Please wait..");

  var countryids = $("#selected_countryid").val();
  var themeids = $("#selected_themeid").val();
  var monthids = $("#selected_monthid").val();

  if (typeof countryids === "undefined") countryids = "";
  if (typeof themeids === "undefined") themeids = "";
  if (typeof monthids === "undefined") monthids = "";

  var infodata = `countryids=${countryids}&monthids=${monthids}&themeids=${themeids}&process=show_itinerary_in_filter`;

  $.post(route("ajax.itinerary-search"), infodata, function (data) {
    $("#show_itin_area").html(data);
  });
}

/**
 * @deprecated
 */
window.show_itinerary_in_tab = function (groupid) {
  // itin tab
  $(".nav-col").find("a").removeClass("itinerary-list-tab--active");

  // Head Tab
  $(`#headtabitin${groupid}`).find("a").addClass("itinerary-list-tab--active");

  // Tab Itin Content
  $(".stab_itin_area").css("display", "none");

  $(`#show_itin_tab_area${groupid}`).css("display", "block");
};

/**
 * @deprecated
 */
window.show_itinlist_more = function (groupid, page) {
  $(`#showmoregroupitin${groupid}`).html("Please wait..");

  var countryids = $("#selected_countryid").val();
  var themeids = $("#selected_themeid").val();
  var monthids = $("#selected_monthid").val();

  if (typeof countryids === "undefined") countryids = "";
  if (typeof themeids === "undefined") themeids = "";
  if (typeof monthids === "undefined") monthids = "";

  var url = route("ajax.itinerary-search");

  var infodata = `countryids=${countryids}&groupid=${groupid}&page=${page}&monthids=${monthids}&themeids=${themeids}&process=show_itinerary_more`;

  $.post(url, infodata, function (data) {
    $(`#showmoregroupitin${groupid}`).replaceWith(data);
  });
};

/**
 * Intrepid: Function Show Itin List.
 * @deprecated
 */
function show_itinerary_in_filter_intrepid(ultratypeid) {
  $(`#show_itin_area${ultratypeid}`).html("Please wait..");

  var countryids = $(`#selected_countryid${ultratypeid}`).val();
  var monthids = $(`#selected_monthid${ultratypeid}`).val();

  if (typeof countryids === "undefined") countryids = "";
  if (typeof monthids === "undefined") monthids = "";

  var infodata = `ultratypeid=${ultratypeid}&monthids=${monthids}&countryids=${countryids}&process=show_itinerary_in_filter_intrepid`;

  $.post(route("ajax.intrepid"), infodata, function (data) {
    $(`#show_itin_area${ultratypeid}`).html(data);

    var totalitin = $("#txttotalitin").val();

    $("#countrytotalitin").html(totalitin);
  });
}

/**
 * @deprecated
 */
window.show_itinerary_in_tab_intrepid = function (groupname, ultratypeid) {
  ultratypeid = 0;

  //Head Tab
  $(`.tabultratype${ultratypeid}`).find("a").removeClass("itinerary-list-tab--active");

  $(`#headtabitin${groupname}_${ultratypeid}`).find("a").addClass("itinerary-list-tab--active");

  //Tab Itin Content
  $(`.stab_itin_area${ultratypeid}`).css("display", "none");
  $(`#show_itin_tab_area${groupname}_${ultratypeid}`).css("display", "block");
};

/**
 * Country Selection Function.
 * @deprecated
 */
window.filter_itin_country_intrepid = function (ultratypeid) {
  ultratypeid = 0;

  var countryid = $(`select[name='itin_country${ultratypeid}'] option:checked`).val();
  var countryids = $(`#selected_countryid${ultratypeid}`).val();

  if (countryids.search(countryid) == -1) {
    var url = route("ajax.intrepid");

    if (countryid == "") {
      $(`#selected_countryid${ultratypeid}`).val();
      countryids = "";

      var vcountry = $(`select[name='itin_country${ultratypeid}'] option:checked`).html();
      var monthids = $(`#selected_monthid${ultratypeid}`).val();

      var infodata = `countryid=${countryid}&ultratypeid=${ultratypeid}&monthids=${monthids}&countryids=${countryids}&process=country_select_ultra`;

      $.post(url, infodata, function (data) {
        $(`select[name='itin_month${ultratypeid}']`).replaceWith(data);

        show_itinerary_in_filter_intrepid(ultratypeid);
      });
    } else {
      add_countryid_intrepid(countryid, ultratypeid);

      countryids = $(`#selected_countryid${ultratypeid}`).val();

      vcountry = $(`select[name='itin_country${ultratypeid}'] option:checked`).html();

      monthids = $(`#selected_monthid${ultratypeid}`).val();

      infodata = `countryid=${countryid}&ultratypeid=${ultratypeid}&monthids=${monthids}&countryids=${countryids}&process=country_select_ultra`;

      $.post(url, infodata, function (data) {
        $(`select[name='itin_month${ultratypeid}']`).replaceWith(data);

        $(".js-filter-chips").append(
          `<div class="filter-chip" id="scountry${ultratypeid}_${countryid}">
             <img src="/imgs/x.png" alt="Remove" onclick="close_country_intrepid('${countryid}','${ultratypeid}');" style="cursor:pointer;">
             ${vcountry}
           </div>`,
        );

        show_itinerary_in_filter_intrepid(ultratypeid);
      });
    }
  }
};

/**
 * @deprecated
 */
function add_countryid_intrepid(countryid, ultratypeid) {
  ultratypeid = 0;

  $(`#selected_countryid${ultratypeid}`).val(countryid);
}

/**
 * @deprecated
 */
window.close_country_intrepid = function (countryid, ultratypeid) {
  ultratypeid = 0;

  del_countryid_intrepid(countryid, ultratypeid);

  $(`#scountry${ultratypeid}_${countryid}`).remove();

  show_itinerary_in_filter_intrepid(ultratypeid);
};

/**
 * @deprecated
 */
function del_countryid_intrepid(countryid, ultratypeid) {
  ultratypeid = 0;

  var countryids = $(`#selected_countryid${ultratypeid}`).val();

  countryids = countryids.replace(countryid, "");
  countryids = countryids.replace("|", "");

  $(`#selected_countryid${ultratypeid}`).val(countryids);
}

/**
 * Month Selection Function.
 * @deprecated
 */
window.filter_itin_month_intrepid = function (ultratypeid) {
  ultratypeid = 0;

  var monthid = $(`select[name='itin_month${ultratypeid}'] option:checked`).val();

  var monthids = $(`#selected_monthid${ultratypeid}`).val();

  if (monthids.search(monthid) == -1) {
    var url = route("ajax.intrepid");

    if (monthid == "") {
      $(`#selected_monthid${ultratypeid}`).val();

      monthids = "";

      var vmonth = $(`select[name='itin_month${ultratypeid}'] option:checked`).html();
      var countryids = $(`#selected_countryid${ultratypeid}`).val();

      var infodata = `monthid=${monthid}&ultratypeid=${ultratypeid}&monthids=${monthids}&countryids=${countryids}&process=month_select_ultra`;

      $.post(url, infodata, function (data) {
        $(`select[name='itin_country${ultratypeid}']`).replaceWith(data);

        show_itinerary_in_filter_intrepid(ultratypeid);
      });
    } else {
      add_monthid_intrepid(monthid, ultratypeid);

      monthids = $(`#selected_monthid${ultratypeid}`).val();

      vmonth = $(`select[name='itin_month${ultratypeid}'] option:checked`).html();
      countryids = $(`#selected_countryid${ultratypeid}`).val();

      infodata = `monthid=${monthid}&ultratypeid=${ultratypeid}&monthids=${monthids}&countryids=${countryids}&process=month_select_ultra`;

      $.post(url, infodata, function (data) {
        $(".js-filter-chips").append(
          `<div class="filter-chip" id="smonth${ultratypeid}_${monthid}">
             <img src="/imgs/x.png" alt="Remove" onclick="close_month_country_intrepid('${monthid}','${ultratypeid}');">
             ${vmonth}
           </div>`,
        );

        $(`select[name='itin_country${ultratypeid}']`).replaceWith(data);

        show_itinerary_in_filter_intrepid(ultratypeid);
      });
    }
  }
};

/**
 * @deprecated
 */
function add_monthid_intrepid(monthid, ultratypeid) {
  ultratypeid = 0;

  $(`#selected_monthid${ultratypeid}`).val(monthid);
}

/**
 * @deprecated
 */
window.close_month_country_intrepid = function (monthid, ultratypeid) {
  ultratypeid = 0;

  del_monthid_intrepid(monthid, ultratypeid);

  $(`#smonth${ultratypeid}_${monthid}`).remove();

  show_itinerary_in_filter_intrepid(ultratypeid);
};

/**
 * @deprecated
 */
function del_monthid_intrepid(monthid, ultratypeid) {
  ultratypeid = 0;

  var monthids = $(`#selected_monthid${ultratypeid}`).val();

  monthids = monthids.replace(monthid, "");
  monthids = monthids.replace("|", "");

  $(`#selected_monthid${ultratypeid}`).val(monthids);
}

/**
 * @deprecated
 */
window.show_itinlist_more_intrepid = function (page, ultratypeid, groupname) {
  ultratypeid = 0;

  $(`#showmoregroupitin${ultratypeid}_${groupname}_${page}`).html("Please wait...");

  var countryids = $(`#selected_countryid${ultratypeid}`).val();
  var monthids = $(`#selected_monthid${ultratypeid}`).val();

  if (typeof countryids === "undefined") countryids = "";
  if (typeof monthids === "undefined") monthids = "";

  var infodata = `ultratypeid=${ultratypeid}&page=${page}&monthids=${monthids}&countryids=${countryids}&groupname=${groupname}&process=show_itinerary_more`;

  $.post(route("ajax.intrepid"), infodata, function (data) {
    $(`#showmoregroupitin${ultratypeid}_${groupname}_${page}`).replaceWith(data);
  });
};
